import styled from 'styled-components'
import * as styles from '../styles'
import { Header1, Copy } from 'components/Typography'
import GoogleAnalytics from 'components/GoogleAnalytics'
import useTranslation from 'next-translate/useTranslation'

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`

function Custom404() {
  const { t, lang } = useTranslation()

  const message = t`404:message`

  return (
    <GoogleAnalytics>
      <Container>
        <Header1 color={styles.OFF_WHITE}>404</Header1>
        <Copy color={styles.OFF_WHITE}>{message} :/</Copy>
      </Container>
    </GoogleAnalytics>
  )
}

export default Custom404
